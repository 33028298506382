/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";

const TarjetasForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id } = props;

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    async function getForm() {
      const response = await http.get(`tarjetas/${id}`);
      if (response) {
        const data = response.data;
        setDisabled(data.activacion !== "-");
        form.setFieldsValue(data);
      }
    }
    getForm();
  }, [id]);

  const onClickSave = async (values) => {
    setLoading(true);

    const response = await http.put(`tarjetas/${id}`, values);

    setLoading(false);

    if (response) {
      await modalSuccess(
        "Tarjeta activada",
        "La tarjeta fue activada exitosamente"
      );
      navigate("/");
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="N° de Tarjeta" name="numero">
            <Input disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="N° de Extintor" name="extintor" rules={[required]}>
            <Input maxLength={50} disabled={disabled} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Prestador" name="prestador">
            <Input disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item label="Fecha de Activación" name="activacion">
            <Input disabled={true} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Activar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default TarjetasForm;
