import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";

import LayoutPage from "./pages/Layout";
import LoginPage from "./pages/Auth/Login";
import ResetPasswordPage from "./pages/Auth/ResetPassword";
import UpdatePasswordPage from "./pages/Auth/UpdatePassword";
import SolicitudesListPage from "./pages/Solicitudes/SolicitudesList";
import SolicitudesDetailPage from "./pages/Solicitudes/SolicitudesDetail";
import TarjetasDetailPage from "./pages/Tarjetas/TarjetasDetail";

import "./app.scss";
import "./styles/alerts.scss";
import "./styles/buttons.scss";
import "./styles/datepickers.scss";
import "./styles/forms.scss";
import "./styles/inputs.scss";
import "./styles/modals.scss";
import "./styles/notifications.scss";
import "./styles/pagination.scss";
import "./styles/selects.scss";
import "./styles/switches.scss";
import "./styles/tabs.scss";
import "./styles/tags.scss";

function App() {
  return (
    <ConfigProvider locale={esES}>
      <Router>
        <Routes>
          <Route path="auth/login" element={<LoginPage />} />
          <Route path="auth/reset" element={<ResetPasswordPage />} />
          <Route path="auth/update" element={<UpdatePasswordPage />} />
          <Route path="/" element={<LayoutPage />}>
            <Route path="tarjetas/:id" element={<TarjetasDetailPage />} />
            <Route path="solicitudes/:id" element={<SolicitudesDetailPage />} />
            <Route path="" element={<SolicitudesListPage />} />
          </Route>
        </Routes>
      </Router>
    </ConfigProvider>
  );
}

export default App;
