import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { FiDollarSign, FiInbox, FiPlus, FiTrash2 } from "react-icons/fi";

import { TableContextProvider } from "./../../store/table-context";
import http from "./../../services/http";
import { confirm, modalSuccess } from "../../services/notifications";
import { getToken, getTokenDecoded } from "../../services/security";

import Card from "./../../components/ui/card";
import Header from "../../components/ui/header";
import Filters from "./../../components/ui/filters";
import Table from "../../components/ui/table";
import SolicitudesEstado from "../../components/solicitudes/solicitudes-estado";
import { redirectToPayment } from "../../services/payments";

const SolicitudesListPage = () => {
  let navigate = useNavigate();

  const title = "Mis Solicitudes";
  const icon = <FiInbox />;
  const breadcrumb = [{ title: "Mis Solicitudes", url: "/" }];
  const [visible, setVisible] = useState(false);

  const filters = [];

  const columns = [
    { title: "Número", property: "numero", sortable: true },
    { title: "Fecha", property: "fecha", sortable: true },
    { title: "Tarjetas", property: "tarjetas", sortable: true },
    {
      title: "Estado",
      property: "estado",
      sortable: true,
      render: (item) => <SolicitudesEstado estado={item.estado} />,
    },
  ];

  const menu = (item) => (
    <Menu>
      {item.estado === 0 && (
        <Menu.Item
          key="2"
          icon={<FiDollarSign />}
          onClick={() => onClickPay(item)}
        >
          Pagar
        </Menu.Item>
      )}
      {item.estado === 0 && (
        <Menu.Item
          key="1"
          icon={<FiTrash2 />}
          onClick={() => onClickDelete(item)}
        >
          Eliminar
        </Menu.Item>
      )}
    </Menu>
  );

  useEffect(() => {
    const token = getToken();

    if (!token) {
      window.location.replace("/auth/login");
      return;
    }

    setVisible(true);
  }, []);

  const onClickAdd = () => {
    navigate("/solicitudes/nuevo");
  };

  const onClickPay = (item) => {
    const token = getTokenDecoded();
    redirectToPayment(item.id, token.cuit, item.tarjetas);
  };

  const onClickDelete = async (item) => {
    const confirmed = await confirm(
      "Eliminar solicitud",
      `¿Esta seguro que desea eliminar la solicitud ${item.numero}?`
    );

    if (!confirmed) {
      return;
    }

    const response = await http.delete(`solicitudes/${item.id}`);
    if (response) {
      await modalSuccess(
        "Solicitud eliminada",
        "La solicitud fue eliminada exitosamente"
      );
      navigate(0);
    }
  };

  const buttons = [
    {
      title: "Agregar Solicitud",
      text: <FiPlus />,
      type: "primary",
      onClick: onClickAdd,
      visible: true,
    },
  ];

  if (!visible) {
    return <React.Fragment />;
  }

  return (
    <TableContextProvider>
      <Card>
        <Header
          title={title}
          icon={icon}
          breadcrumb={breadcrumb}
          buttons={buttons}
        />
        <Filters fields={filters} />
        <Table
          id="table-solicitudes"
          columns={columns}
          menu={menu}
          url="/solicitudes"
          orderBy="fecha"
          orderDirection="descending"
        />
      </Card>
    </TableContextProvider>
  );
};

export default SolicitudesListPage;
