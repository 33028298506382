import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FiInbox } from "react-icons/fi";

import { getAction } from "../../services/security";

import Card from "../../components/ui/card";
import Header from "../../components/ui/header";
import SolicitudesForm from "./../../components/solicitudes/solicitudes-form";

const SolicitudesDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const readonly = searchParams.get("view") === "readonly";

  const action = getAction(id, true, false, readonly);
  const title = `${action} Solicitud`;
  const icon = <FiInbox />;
  const breadcrumb = [
    { title: "Mis Solicitudes", url: "/" },
    { title: action, url: `/solicitudes/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <SolicitudesForm id={id} readonly={readonly} />
    </Card>
  );
};

export default SolicitudesDetailPage;
