import { Tag } from "antd";

const SolicitudesEstado = (props) => {
  const { className, estado } = props;

  let color;
  let text;

  switch (estado) {
    case 0:
      color = "gold";
      text = "Pendiente";
      break;

    case 1:
      color = "green";
      text = "Pagada";
      break;

    case 2:
      color = "purple";
      text = "Entregada";
      break;

    case 3:
      color = "red";
      text = "Eliminada";
      break;

    default:
      break;
  }

  return (
    <Tag className={className} color={color}>
      {text}
    </Tag>
  );
};

export default SolicitudesEstado;
