export const redirectToPayment = async (id, cuit, tarjetas) => {
  const baseUrl =
    "https://vps.infogov.com.ar/rentas/guaymallen/#/public/varios";
  const concepto = "607";
  const solicitud = id.toString().padStart(8, "0");
  const url =
    baseUrl +
    `?conceptos=${concepto}&` +
    `cuil=${cuit}&` +
    `solicitud=${solicitud}&` +
    `cantidad=${tarjetas}`;
  window.open(url, "_blank");
};
