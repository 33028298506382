/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";

import { required } from "../../services/forms";
import http from "../../services/http";
import { modalSuccess } from "../../services/notifications";
import { getTokenDecoded } from "../../services/security";
import { redirectToPayment } from "../../services/payments";

const SolicitudesForm = (props) => {
  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { id, readonly } = props;

  const CREAR = true;
  const EDTIAR = false;
  const disabled = readonly || (id === "nuevo" ? !CREAR : !EDTIAR);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getForm() {
      form.setFieldsValue({ numero: "-" });

      if (id === "nuevo") {
        return;
      }

      const response = await http.get(`solicitudes/${id}`);
      if (response) {
        const data = response.data;
        form.setFieldsValue(data);
      }
    }

    getForm();
  }, [id]);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickSave = async (values) => {
    setLoading(true);

    const token = getTokenDecoded();
    values.prestador = token.id;

    const response = await http.post("solicitudes", values);

    setLoading(false);

    if (response) {
      redirectToPayment(response.data.id, token.cuit, values.tarjetas);

      await modalSuccess(
        "Solicitud guardada",
        id === "nuevo"
          ? "La solicitud fue creada exitosamente"
          : "La solicitud fue modificada exitosamente"
      );

      navigate(-1);
    }
  };

  return (
    <Form layout="vertical" form={form} onFinish={onClickSave}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item label="Número" name="numero">
            <Input disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24}>
          <Form.Item
            label="Cantidad de tarjetas"
            name="tarjetas"
            rules={[required]}
          >
            <InputNumber min={1} max={1000} disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>

      <div className="actions">
        <Button type="text" onClick={onClickBack}>
          Volver
        </Button>
        {!disabled && (
          <Button type="primary" htmlType="submit" loading={loading}>
            {!loading && "Guardar"}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default SolicitudesForm;
