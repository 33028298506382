import jwt from "jwt-decode";

export const getToken = () => {
  const token = localStorage.getItem("token");
  return token ? `Bearer ${token}` : undefined;
};

export const getTokenDecoded = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return undefined;
  }

  return jwt(token);
};

export const getAction = (id, create, edit, readonly) => {
  if (readonly) {
    return "Ver";
  }

  if (id === "nuevo") {
    return create ? "Crear" : "Ver";
  }

  return edit ? "Crear" : "Ver";
};
