import { Outlet } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ToastContainer } from "react-toastify";

import Navbar from "./../components/ui/navbar";

import classes from "./Layout.module.scss";

const LayoutPage = () => {
  return (
    <div className={classes.layout}>
      <div className={classes.container}>
        <SimpleBar
          style={{
            height: "100vh",
            maxHeight: "100vh",
            width: "100%",
            padding: "20px 20px 0 20px",
          }}
        >
          <Navbar />
          <Outlet />
          <ToastContainer />
        </SimpleBar>
      </div>
    </div>
  );
};

export default LayoutPage;
