import { useParams, useSearchParams } from "react-router-dom";
import { FiImage } from "react-icons/fi";

import Card from "../../components/ui/card";
import TarjetasForm from "../../components/tarjetas/tarjetas-form";
import Header from "../../components/ui/header";

const TarjetasDetailPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const readonly = searchParams.get("view") === "readonly";

  const title = "Activar Tarjeta";
  const icon = <FiImage />;
  const breadcrumb = [
    { title: "Tarjetas" },
    { title: "Activar", url: `/tarjetas/${id}` },
  ];

  return (
    <Card>
      <Header title={title} icon={icon} breadcrumb={breadcrumb} />
      <TarjetasForm id={id} readonly={readonly} />
    </Card>
  );
};

export default TarjetasDetailPage;
